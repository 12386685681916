<!--
  Reference Numbers Page
-->
<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="10" v-show="showBools.FiltersAreaCombo">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol sm="2" v-show="showBools.FilterFY">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <TableRefNumbers :tableData="processedData">
          <span slot="area"> {{ selectedArea }} {{ selectedArea && ' / ' }} </span>
          <span slot="fiscalYear">{{ selectedFy }}</span>
        </TableRefNumbers>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";

const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filters-area-combo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const TableRefNumbers = () =>
  import(
    /* webpackChunkName: "table-ref-numbers" */ "@/components/organisms/TableRefNumbers"
  );
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");

import formatNum from "@/utils/formatNum";
import currentFY from "@/services/CurrentFY";
import filtersByProfile from "@/helpers/filtersByProfile";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

export default {
  name: "ReferenceNumbers",
  components: {
    FiltersAreaCombo,
    FilterFY,
    TableRefNumbers,
    Loader,
  },
  data() {
    return {
      filtersList: null,
      activeItems: [],
      collapse: false,
      cardCollapse: true,
      isCollapsed: true,
      areaError: false,
      showLoader: false,
      showTable: false,
      processedData: null,
      showBools: {
        FiltersAreaCombo: false,
        FilterFy: false,
      },
      tableId: "excelTest",
      selectedFy: null,
      selectedArea: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    // Refactor
    /**
     * Implicit parameters are those that are already known
     * based on the user's profile, so that not all filters have to be shown.
     */
    implicitParams() {
      const n = this.user.profileId;
      if (n >= 20 && n < 30) {
        return {
          concessionCode: this.user.concessionCode,
        };
      }
      return null;
    },
  },
  methods: {
    /**
     * Define Request default parameters and call the query function
     */
    makeDefaultQuery() {
      currentFY().then((fiscalYear) => {
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {};
        defaults[defaultArea.key] = defaultArea.value;
        defaults.fiscalYear = fiscalYear;
        this.selectedFy = formatFiscalYear(fiscalYear);

        this.makeApiQuery(defaults);
      });
    },
    /**
     * Handling filter values and calling the query function
     */
    submitFilterValues(event) {
      event.preventDefault();
      /**
       * Variable to check required values
       * Define parameters
       */
      let params,
        validated = false;
      // Retrieves implicit parameters if any
      if (this.implicitParams) {
        params = { ...this.implicitParams };
        validated = true;
      } else {
        params = { ...this.$store.getters.getFiltersAreaComboParams };
        if (!this.$store.getters.getFiltersAreaComboParams) {
          // Show area filter validation error
          this.areaError = true;
        } else {
          // Hide area filter validation error
          this.areaError = false;
          validated = true;
        }
      }

      if (validated) {
        const fiscalYear = this.$store.getters.getFilterFYValue;
        this.selectedFy = formatFiscalYear(fiscalYear);
        params.fiscalYear = fiscalYear;
        // Make API query with filter values
        this.makeApiQuery(params);
      }
    },
    makeApiQuery(params) {
      // Show the loader and hide the table until data is received
      this.showLoader = true;
      this.showTable = false;
      // API call sending parameters
      Api.sendPost("basicReport/referenceNumberYearly", params).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              // Hide the loader and sow the table
              this.showLoader = false;
              this.showTable = true;
              // Set the processed data variable to send to the DataTable component
              this.selectedArea = getAreaName(params, this.messages);
              this.processedData = this.processRawData(response.data.data);
            }
          }
        }
      );
    },

    /**
     * @description Get raw data and process it
     * @param {Object} rawData - Raw data
     * @returns {array} data - Processed data
     */
    processRawData(rawData) {
      const data = [];
      rawData.quarterlyList.map((quarter) => {
        let refNums = quarter.referenceNumberList;
        let total = quarter.totalAmount;
        let row;
        refNums.forEach((elem) => {
          row = {
            mes: this.messages.filters.months[elem.month].toUpperCase(),
            bonoVolumen: `${formatNum.get(elem.bonusVolume, 2)}€`,
            accesorios: `${formatNum.get(elem.accessories, 2)}€`,
            parque: formatNum.get(elem.park, 2),
            _classes: "focus",
          };
          data.push(row);
        });
        row = {
          mes: this.messages.tables.quarterlyTotal,
          bonoVolumen: `${formatNum.get(total.bonusVolume, 2)}€`,
          accesorios: `${formatNum.get(total.accessories, 2)}€`,
          parque: formatNum.get(total.park, 2),
          _classes: "dark-gray",
        };
        data.push(row);
      });
      const totalY = rawData.totalAmount;
      const yearlyTotals = {
        mes: "TOTAL ANUAL",
        bonoVolumen: `${formatNum.get(totalY.bonusVolume)}€`,
        accesorios: `${formatNum.get(totalY.accessories)}€`,
        parque: formatNum.get(totalY.park),
        _classes: "dark-gray-2",
      };
      data.push(yearlyTotals);
      return data;
    },
    /**
     * @description Show different filters by the filtersNames array
     * @param {array} filtersNames - array with filters to show
     */
    mountFilters(filtersNames) {
      filtersNames.forEach((elem) => {
        if (typeof elem === "string") {
          this.showBools[elem] = true;
        } else if (typeof elem === "object") {
          this.showBools.FiltersAreaCombo = true;
          this.activeItems = elem.FiltersAreaCombo;
        }
      });
    },
  },
  created() {
    this.makeDefaultQuery();
    this.filtersList = filtersByProfile(
      "referenceNumbers",
      this.user.profileId
    );
    this.mountFilters(this.filtersList);
  },
};
</script>
